import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { extend, QueryContext } from '@thd-nucleus/data-sources';
import { useStore, ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { CustomerContext } from '@thd-olt-functional/customer-information';
import { LayoutRenderer } from '@thd-olt-component-react/layout-renderer';
import { declareContexts } from '@thd-olt-component-react/hydrator';
import { useLocation } from '@thd-olt-component-react/router';
import { Metadata, HomepageMetadata, NoIndexNoFollowMetadata } from '@thd-olt-component-react/metadata';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { useUrlTmxId } from '../../hooks/useUrlTmxId';

import { componentOptions } from './component-options';
import {
  CUSTOMER_TYPES,
  userAuthorizationStatus,
  getSvocId,
  getUserId,
  getMarketingCloudVisitorID,
  getGuestUserId,
  getHydrationOptions,
} from '../../utils';
import {
  guestB2cServerSideDataModel,
  guestB2cClientSideDataModel,
  homepagePreviewByIDDataModelServerSide,
  homepagePreviewByIDDataModelClientSide
} from '../../datamodels/home';
import { components } from '../../components/components';
import { usePreviewByID } from '../../hooks';
import { HomepageProvider, HomepageContext } from '../../components/context';

const title = 'The Home Depot';

const staticMetadata = {
  title,
  description:
    'Shop online for all your home improvement needs: appliances, bathroom decorating ideas, kitchen remodeling, patio furniture, power tools, bbq grills, carpeting, lumber, concrete, lighting, ceiling fans and more at The Home Depot.', // eslint-disable-line
  facebookAdmins: '100005416960152',
  siteName: title,
  type: 'homepage',
  canonicalURL: '/',
  robots: 'noodp',
};

// should probably be moved outside this file
function renderMetadata(metadata, pageID, heroCarouselData, isPreviewPage) {
  return (
    <ErrorBoundary name="metadata">
      <Metadata>
        {isPreviewPage && (
          <NoIndexNoFollowMetadata />
        )}
        <HomepageMetadata
          pageID={pageID}
          metadata={{ ...staticMetadata, ...metadata }}
          preloadHeroImages
          heroCarouselData={heroCarouselData}
        />
      </Metadata>
    </ErrorBoundary>
  );
}

export const GuestHomepage = ({ slug, thdSEOBotDetection }) => {
  declareContexts([QueryContext, ExperienceContext, HomepageContext, CustomerContext], { freeze: true });
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const guestHPBackup = useConfigService('guestHPBackup');
  const store = useStore({ varnish: false });
  const userState = {
    userId: getSvocId() || getUserId(),
    mcvisID: getMarketingCloudVisitorID(),
    userAuthStatus: userAuthorizationStatus(),
    guestUserId: getGuestUserId(),
  };

  const { guestUserId, mcvisID, userAuthStatus } = userState;
  const contentfulPreviewId = usePreviewByID();
  const { search: querystring, pathname } = useLocation();

  const pathWithQueryParams = `${pathname}${querystring}`;
  const isPreviewPage = pathWithQueryParams.indexOf('render*') > -1
    || pathWithQueryParams.indexOf('contentPV') > -1
    || pathWithQueryParams.indexOf('bassPV') > -1;

  let layoutDataModel = {
    server: extend({}, guestB2cServerSideDataModel, HomepageMetadata),
    client: guestB2cClientSideDataModel
  };
  /**
   * This is not a class in the CSS sense. It's referring
   * to the type of layout we have in Contentful, which
   * at the time of writing seems to be a choice between
   * PersonalizedHomepage and EducationalLayout.
   */
  let layoutClass;

  if (contentfulPreviewId) {
    layoutDataModel = {
      server: homepagePreviewByIDDataModelServerSide,
      client: homepagePreviewByIDDataModelClientSide
    };
    layoutClass = 'PersonalizedHomePage';
  }

  useEffect(function triggerHomepageLoadEvent() {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'guest-homepage.page-load',
      'homepage',
    );
  }, []);

  useEffect(function setThdOnlineChannelCookie() {
    if (channel === 'mobile' && !isConsumerApp) {
      const encodedValue = encodeURIComponent('E1=S2');
      window.cookieUtils.fed_CreateCookie(
        'THD_ONLINE_CHANNEL',
        encodedValue,
        1,
      );
    }
  }, []);

  return (
    <HomepageProvider
      value={{
        thdSEOBotDetection,
        hydrationOptions: getHydrationOptions(channel, isConsumerApp),
        customerType: CUSTOMER_TYPES.GUEST,
        urlTmxId: useUrlTmxId(),
        guestUserId,
        mcvisID,
        store,
        userAuthStatus,
      }}
    >
      <LayoutRenderer
        components={components}
        componentOptions={componentOptions}
        slug={slug}
        contentfulPreviewId={contentfulPreviewId}
        layoutClass={layoutClass}
        layoutDataModel={layoutDataModel}
        onContentInformationApiError={() => {
          if (typeof window !== 'undefined') {
            window.location.replace(guestHPBackup);
          }
        }}
        ssr
        renderMetadata={(metadata, pageID, heroCarouselData) => {
          return renderMetadata(metadata, pageID, heroCarouselData, isPreviewPage);
        }}
        customerType={CUSTOMER_TYPES.GUEST}
      />
    </HomepageProvider>
  );
};

GuestHomepage.propTypes = {
  slug: PropTypes.string,
  thdSEOBotDetection: PropTypes.bool
};
GuestHomepage.defaultProps = {
  slug: undefined,
  thdSEOBotDetection: false
};

GuestHomepage.displayName = 'GuestHomepage';
