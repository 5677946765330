import React from 'react';
import PropTypes from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';

import { GuestHomepage } from './GuestHomepage';

export const GuestHomepageContainer = ({ slug, thdSEOBotDetection }) => {
  return (
    <ErrorBoundary name="my-homepage-content">
      <QueryProvider cacheKey="consumer-homepage">
        <GuestHomepage slug={slug} thdSEOBotDetection={thdSEOBotDetection} />
      </QueryProvider>
    </ErrorBoundary>
  );
};

GuestHomepageContainer.propTypes = {
  slug: PropTypes.string,
  thdSEOBotDetection: PropTypes.bool
};

GuestHomepageContainer.defaultProps = {
  slug: undefined,
  thdSEOBotDetection: false
};
